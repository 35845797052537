import React, { Component } from 'react';
import Ripples from 'react-ripples';

export class Column extends Component {
    render() {
        return (<div className={`col col-${this.props.index}`}>
            {
                this.props.ripples === false ?
                    <div style={{ padding: '10px 0px' }} onClick={this.props.onClick ? (x, e) => this.props.onClick(x, e) : null}>
                        {this.props.children}
                    </div>
                    :
                    <Ripples>
                        <div style={{ padding: '10px 0px' }} onClick={this.props.onClick ? (x, e) => this.props.onClick(x, e) : null}>
                            {this.props.children}
                        </div>
                    </Ripples>
            }
        </div>);
    }
}