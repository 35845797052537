import React, { Component } from 'react';

export class LighthouseGauge extends Component {
    render() {
        return (<a className="lh-gauge__wrapper lh-gauge__wrapper--pass" href={`#${this.props.title.toLowerCase()}`} onClick={(e) => e.preventDefault()}>
            <div className="lh-gauge__svg-wrapper">
                <svg className="lh-gauge" viewBox="0 0 120 120">
                    <circle className="lh-gauge-base" r="56" cx="60" cy="60" strokeWidth="8"/>
                    <circle className="lh-gauge-arc" r="56" cx="60" cy="60" strokeWidth="8" style={{transform: 'rotate(-87.9537deg)', strokeDasharray: '351.858, 351.858'}}/>
                </svg>
            </div>
            <div className="lh-gauge__percentage">{this.props.score}</div>
            <div className="lh-gauge__label">{this.props.title}</div>
        </a>);
    }
}