import React, { Component } from 'react';

export class IframeWithHtmlString extends Component {
    constructor(props) {
        super(props);

        this.iframeRef = React.createRef();
    }

    loadiFrame() {
        const iframeDocument = this.iframeRef.current.contentDocument;
        iframeDocument.open();
        iframeDocument.write(this.props.htmlString);
        iframeDocument.close();
      
        document.title += `- ${iframeDocument.title}`;
        document.getElementById('root').style.overflow = "hidden";

        if (localStorage.getItem('dark') !== 'true') {
            iframeDocument.getElementsByClassName('lh-root')[0].classList.remove("lh-dark");
        }
    }

    componentDidMount() {
        this.loadiFrame();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.htmlString !== this.props.htmlString && this.iframeRef.current) {
            this.loadiFrame();
        }
    }

    render() {
        return (
            <iframe
                ref={this.iframeRef}
                title="Lighthouse Report"
                width="100%"
                height="100%"
            />
        );
      }
}
