import React, { Component } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import './TooltipLink.css';

export class TooltipLink extends Component {
    render() {
        return (<OverlayTrigger
            delay={{ hide: 300, show: 300 }}
            overlay={(props) => (
                <Tooltip {...props} id={this.props.id}>
                    {this.props.content}
                </Tooltip>
            )}
            placement={this.props.position}
        >{this.props.children}
        </OverlayTrigger>);
    }
}