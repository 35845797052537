import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import '../utils';

import './Form.css';

export class Form extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: []
        };
    }

    fieldValidation(field, value) {
        let errors = [];

        if (field.required && value === '') {
            errors.push(this.props.content.errors[0].format(field.label));
        }
        else {
            const fieldErrors = this.props.validation(field, value, this.props.content.errors);
            if (fieldErrors.length > 0) {
                errors.push(fieldErrors);
            }
        }

        return errors;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.submit !== this.props.submit && this.props.submit) {
            var formData = {};
            this.props.content.fields.forEach(x => {
                formData[x.name] = document.getElementById(x.name).value.replace(/\r?\n/g, '<br />');
            });

            let errors = [];

            for (let i = 0; i < this.props.content.fields.length; ++i) {
                let field = this.props.content.fields[i];
                const fieldErrors = this.fieldValidation(field, formData[field.name]);
                if (fieldErrors.length > 0) {
                    errors.push(fieldErrors);
                }
            }

            this.setState({
                errors: errors
            });
    
            this.props.action(errors.length > 0, formData);
        }
    }

    onFocus(e) {
        e.target.parentElement.firstChild.classList.add("focused");
    }

    onBlur(e) {
        if (e.target.value === '') {
            e.target.classList.remove("has-content");
            e.target.parentElement.firstChild.classList.remove("focused");
        }
        else {
            e.target.classList.add("has-content");
        }
    }

    render() {
        return (
            <>
                {this.props.content.text && <p>{this.props.content.text}</p>}
                {this.props.content.more && <p>{this.props.content.more}</p>}
                <form id="form">
                    {this.props.content.fields.map((x, i) => {
                        return (<div className="input-effect" key={i}>
                            <label>{x.label}{x.required && '*'}</label>
                            {x.type === 'textarea' ?
                                <textarea className="effect-19" placeholder="" name={x.name} id={x.name} required={x.required} onFocus={(e) => this.onFocus(e)} onBlur={(e) => this.onBlur(e)}></textarea> :
                                <input className="effect-19" type={x.type} placeholder="" name={x.name} id={x.name} required={x.required} onFocus={(e) => this.onFocus(e)} onBlur={(e) => this.onBlur(e)} />
                            }
                            <span className="focus-border">
                                <i></i>
                            </span>
                        </div>);
                    })}
                </form>
                {this.state.errors.length > 0 &&
                    <div className="box-content error">
                        {this.state.errors.map((x, i) => {
                            return (<p key={i}>
                                <FontAwesomeIcon icon={faTriangleExclamation} /> {x}
                            </p>);
                        })}
                    </div>
                }
            </>);
    }
}