import React, { Component } from 'react';
import { LeftPanel } from './LeftPanel';
import { BaggageRow } from './BaggageRow';
import { LanguageColumn } from './LanguageColumn';
import { SkillsRow } from './SkillsRow';
import { HobbiesRow } from './HobbiesRow';
import { FooterMobile } from './FooterMobile';
import { InfoBox } from './InfoBox';
import { Menu } from './Menu';
import { Header } from './Header';
import { Footer } from './Footer';

export class MainPage extends Component {
    constructor(props) {
        super(props);

        this.originalTitle = this.originalTitle ?? document.title;

        this.state = {
            infoBox: {
                icon: null,
                content: null
            },
            confidentialInfo: null,
        }

        this.activeItem = null;

        this.changeLanguage = this.changeLanguage.bind(this);
        this.toggleTheme = this.toggleTheme.bind(this);
        this.toggleContrast = this.toggleContrast.bind(this);
        this.refreshInfoBox = this.refreshInfoBox.bind(this);
    }
    
    componentDidMount() {
        if (!!this.props.content) {
            document.title = `${this.originalTitle} - ${this.props.content.global.title}`;
        }
    }

    toggleTheme(darkTheme) {
        this.setState({
            darkTheme: darkTheme
        });
    }

    toggleContrast(highContrast) {
        this.setState({
            highContrast: highContrast
        });
    }

    changeLanguage(e, language) {
        e.preventDefault();
        
        this.props.changeLanguage(language, () => {
            if (this.activeItem) {
                let element = this.activeItem.target;
                do {
                    element = element.parentElement;
                } while (element.className !== 'panel-content')
                let key = Object.keys(this.props.content.mainPanel).find(x => this.props.content.mainPanel[x].id === element.previousSibling.id);
                this.refreshInfoBox(this.props.content.mainPanel[key].items[this.activeItem.index], this.activeItem.target, this.activeItem.index);
            }
        });       
    }

    refreshInfoBox(content, target, index) {
        this.activeItem = {
            target: target,
            index: index
        };

        this.setState({
            infoBox: {
                icon: target.firstChild,
                content: content
            }
        });
    }

    render() {
        return (<div id="container">
            <Header content={this.props.content.header} onOpen={(e, content) => this.props.onOpen(e, content)} accessCode={this.props.accessCode} />
            <div className="content">
                <LeftPanel content={this.props.content.leftPanel}
                    onOpen={(e, content) => this.props.onOpen(e, content)}
                    language={this.props.language}
                    changeLanguage={(e, language) => this.changeLanguage(e, language)}
                    onToggleTheme={this.toggleTheme}
                    onToggleContrast={this.toggleContrast}
                    accessCode={this.props.accessCode} />

                <div id="main-panel" className="column">
                    <BaggageRow content={this.props.content.mainPanel.jobs} language={this.props.language} onClick={(content, target, index) => this.refreshInfoBox(content, target, index)} />
                    <BaggageRow content={this.props.content.mainPanel.curriculum} language={this.props.language} onClick={(content, target, index) => this.refreshInfoBox(content, target, index)} />
                    <LanguageColumn content={this.props.content.mainPanel.languages} onClick={(content, target, index) => this.refreshInfoBox(content, target, index)} />
                    <SkillsRow content={this.props.content.mainPanel.skills} onClick={(content, target, index) => this.refreshInfoBox(content, target, index)} />
                    <HobbiesRow content={this.props.content.mainPanel.hobbies} onClick={(content, target, index) => this.refreshInfoBox(content, target, index)} />
                    <FooterMobile content={this.props.content.footer} onOpen={(e, content) => this.props.onOpen(e, content)} accessCode={this.props.accessCode} />
                </div>

                <div id="right-panel" className="column">
                    <InfoBox content={this.props.content.rightPanel.infoBox} values={this.state.infoBox} language={this.props.language} global={this.props.content.global} isDark={this.state.darkTheme} />
                    <div className="content">
                        <Menu content={this.props.content.mainPanel} />
                        <Footer content={this.props.content.footer} onOpen={(e, content) => this.props.onOpen(e, content)} accessCode={this.props.accessCode} />
                    </div>
                </div>
            </div>
        </div>);
    }
}
