import React, { Component } from 'react';
import { getDate, buildHyperlink } from '../utils';
import { LighthouseSummary } from './LighthouseSummary';

export class About extends Component {
    render() {
        return (<>
            <p>{this.props.content.author}</p>
            <p>{this.props.content.technologies.text}</p>
            <ul>
                {this.props.content.technologies.items.map((x, i) => {
                    return <li key={i}>{x}</li>;
                })}
            </ul>
            <p>{this.props.content.credits.text}</p>
            <ul>
                {this.props.content.credits.items.map((x, i) => {
                    return <li key={i}>{buildHyperlink(x, this.props.content.credits.urls)}</li>;
                })}
            </ul>
            <p>{this.props.content.host}</p>
            <LighthouseSummary content={this.props.content.lighthouse} language={this.props.language} />
            <p><i>{this.props.content.lastUpdated.text} {getDate(this.props.content.lastUpdated.date, this.props.language)}.</i></p>
        </>);
    }
}