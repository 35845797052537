// eslint-disable-next-line
String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined'
            ? args[number]
            : match
            ;
    });
};

export function getDate(stringDate, language) {
    return stringDate && stringDate.includes('-') ? new Date(stringDate).toLocaleDateString(language, {
        year: 'numeric',
        month: 'long'
    }) : stringDate;
}

export function buildHyperlink(text, urls) {
    const components = [];
    let currentIndex = 0;
  
    const keys = Object.keys(urls).sort((a, b) => text.indexOf(urls[a]) - text.indexOf(urls[b]));
    const values = Object.values(urls).sort((a, b) => text.indexOf(a) - text.indexOf(b));
  
    keys.forEach((key, i) => {
        const start = text.indexOf(key, currentIndex);
        if (start !== -1) {
            const partBefore = text.substring(currentIndex, start);
            if (partBefore) {
                components.push(<span key={`part_before_${i}`}>{partBefore}</span>);
            }
    
            const url = values[i];
            components.push(<a key={`link_${i}`} href={url} target="_blank" rel="noreferrer">{key}</a>);
            currentIndex = start + key.length;
        }
    });
  
    if (currentIndex < text.length) {
        const remainingPart = text.substring(currentIndex);
        components.push(<span key="remaining_part">{remainingPart}</span>);
    }
  
    return components;
}

export function isValidEmail(value) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
} 

export function isValidPhone(value) {
    // eslint-disable-next-line
    return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(`+${value.countryCode}${value.number}`);
}

export function isValidDate(value) {
    return !isNaN(Date.parse(value));
}

export function appendParamToLink(link, param) {
    if (param === null) {
        return link;
    }

    return `${link}${link.includes('?') ? '&' : '?'}key=${param}`;
}

export function checkIntegrityAndDecode(accessCode) {
    let confidentialInfo = null;
    try {
        confidentialInfo = JSON.parse(atob(accessCode));
        if (!isValidEmail(confidentialInfo.email) || !isValidPhone(confidentialInfo.phone) || !isValidDate(confidentialInfo.dateOfBirth)) {
            confidentialInfo = null;
        }
    }
    catch { }

    return confidentialInfo;
}

export function isValidAccessCode(accessCode) {
    return checkIntegrityAndDecode(accessCode)?.version?.toString() === process.env.REACT_APP_CONFIDENTIAL_INFORMATION_VERSION;
}