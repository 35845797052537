import React, { Component } from 'react';
import { Route, Routes } from "react-router-dom";
import LocalizedStrings from 'react-localization';
import { MainPage } from './MainPage';
import { CustomModal } from './CustomModal';
import { About } from './About';
import { ContactForm } from './ContactForm';
import { AccessCodeForm } from './AccessCodeForm';
import { NotFound } from '../NotFound';
import { IframeWithHtmlString } from "./IframeWithHtmlString";
import { withRouter } from '../withRouter';
import { isValidAccessCode, checkIntegrityAndDecode } from '../utils';
import ContentEn from '../content/en.json';
import ContentFr from '../content/fr.json';

var __html = require('./LighthouseReport.html.js');

class Layout extends Component {
    ROUTES = [ '/contact', '/accessCode', '/about' ];

    constructor(props) {
        super(props);

        this.originalTitle = this.originalTitle ?? document.title;

        this.content = new LocalizedStrings({
            en: ContentEn,
            fr: ContentFr
        });

        const params = new URLSearchParams(props.location.search);

        let language = params.get("locale") ?? localStorage.getItem('language') ?? (window.navigator.userLanguage || window.navigator.language).toLowerCase().split('-')[0];
        if (language !== 'fr') {
            language = 'en';
        }

        const accessCode = params.get('key');

        this.state = {
            language: language,
            show: false,
            modalTitle: null,
            accessCode: accessCode
        }

        if (isValidAccessCode(accessCode)) {
            this.setConfidentialInfo(checkIntegrityAndDecode(accessCode), accessCode);
        }

        this.changeLanguage = this.changeLanguage.bind(this);
        this.setConfidentialInfo = this.setConfidentialInfo.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        this.openDefaultModal();
    }

    openDefaultModal() {
        let title = null;
        const content = this.state.language === 'fr' ? ContentFr : ContentEn;

        switch(this.props.location.pathname) {
            case '/about':
                title = content.footer.about.text;
                break;
            case '/contact': 
                title = content.footer.contact.text;
                break;
            case '/accessCode': 
                title = content.leftPanel.contact.accessCode;
                break;
            default:
                title = null;
                break;
        }

        this.setState({
            show: this.ROUTES.includes(this.props.location.pathname),
            modalTitle: title
        });
    }

    setConfidentialInfo(confidentialInfo, accessCode) {
        if (confidentialInfo) {
            this.replacePlaceholderInfo(ContentEn, confidentialInfo);
            this.replacePlaceholderInfo(ContentFr, confidentialInfo);
            
            this.content.setContent({
                en: ContentEn,
                fr: ContentFr
            });

            this.setState({
                confidentialInfo: confidentialInfo,
                accessCode: accessCode
            });
        }
    }

    replacePlaceholderInfo(content, confidentialInfo) {
        content.leftPanel.about.more.dateOfBirth = confidentialInfo.dateOfBirth;
        content.leftPanel.contact.phone.text = confidentialInfo.phone;
        content.leftPanel.contact.email.text = confidentialInfo.email;
    }

    changeLanguage(language, callback) {
        this.setState({
            language: language
        }, () => {
            document.title = `${this.originalTitle} - ${this.content.global.title}`;
            localStorage.setItem('language', language);
            callback();
        });
    }

    onOpen(e, content) {
        e.preventDefault();

        this.setState({
            show: true,
            modalTitle: content
        });
    }

    onClose() {
        this.setState({
            show: false,
            modalTitle: null
        });
    }

    render() {
        this.content.setLanguage(this.state.language);

        return (<Routes>
            {['/', '/index.html'].concat(this.ROUTES).map((x, i) => {
                return (<Route key={i} exact path={x} element={<>
                    <MainPage content={this.content}
                        language={this.state.language}
                        onOpen={this.onOpen}
                        confidentialInfo={this.state.confidentialInfo}
                        changeLanguage={this.changeLanguage}
                        accessCode={this.state.accessCode} />
                    <CustomModal content={this.content.modal} show={this.state.show} title={this.state.modalTitle} onClose={this.onClose}>
                        {this.state.modalTitle === this.content.footer.about.text ?
                            <About content={this.content.modal.about} language={this.state.language} />
                            : this.state.modalTitle === this.content.footer.contact.text ?
                                <ContactForm content={this.content.modal.contact} />
                                : <AccessCodeForm content={this.content.modal.accessCode} setConfidentialInfo={this.setConfidentialInfo} />
                        }
                    </CustomModal>
                </>} />);
            })};

            <Route exact path="/LighthouseReport.html" element={<IframeWithHtmlString htmlString={__html} />} />

            <Route path="*" element={<>
                <NotFound content={this.content.notFound} header={this.content.header} language={this.state.language} onOpen={this.onOpen} accessCode={this.state.accessCode} />
                <CustomModal content={this.content.modal} show={this.state.show} title={this.state.modalTitle} onClose={this.onClose}>
                    <ContactForm content={this.content.modal.contact} />
                </CustomModal>
            </>} />
        </Routes>);
    }
}

export default withRouter(Layout);