import React, { Component } from 'react';
import Ripples from 'react-ripples';
import { BoxContent } from './BoxContent';

import './HobbiesRow.css';

export class HobbiesRow extends Component {
    render() {
        return (<BoxContent id={this.props.content.id} title={this.props.content.title}>
            {this.props.content.items.map((x, i) => {
                return (<div className="col hobby" key={i}>
                    <Ripples>
                        <div className="hobby-container" onClick={(e) => this.props.onClick(x, e.currentTarget, i)} id={x.id}>
                            <img src={`images/${x.image}`} className="img" alt={x.title} />
                            <div className="middle">
                                <div className="text">{x.title}</div>
                            </div>
                            <div className="more"><span className="more-information">{x.more}</span></div>
                        </div>
                    </Ripples>
                </div>);
            })}
        </BoxContent>);
    }
}