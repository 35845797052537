import React, { Component } from 'react';
import Ripples from 'react-ripples';
import { TooltipLink } from './TooltipLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';

import init from '../script.js';
import { appendParamToLink } from '../utils';

import './Header.css';

export class Header extends Component {
    componentDidMount() {
        init();
    }

    render() {
        return (<header className={this.props.className}>
            <svg id="header" viewBox={"0 0 1600 600"} preserveAspectRatio="xMidYMid slice">
                <defs>
                    <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0" colorInterpolation="sRGB">
                        <stop id="stop1a" offset="0%" stopColor="#1860a1"></stop>
                        <stop id="stop1b" offset="100%" stopColor="#1860a1"></stop>
                    </linearGradient>
                    <linearGradient id="grad2" x1="0" y1="0" x2="1" y2="0" colorInterpolation="sRGB">
                        <stop id="stop2a" offset="0%" stopColor="#1860a1"></stop>
                        <stop id="stop2b" offset="100%" stopColor="#1860a1"></stop>
                    </linearGradient>
                </defs>
                <rect id="rect1" x="0" y="0" width="1600" height="100" stroke="none" fill="url(#grad1)"></rect>
                <rect id="rect2" x="0" y="0" width="1600" height="100" stroke="none" fill="url(#grad2)"></rect>
            </svg>
            <div className="content">
                <TooltipLink content={this.props.content.bonjour} position="right" id="hello">
                    <img id="picture" src="images/logo.jpeg" alt={this.props.content.title} />
                </TooltipLink>
                <h1>
                    <Ripples>
                        <a href={appendParamToLink('/', this.props.accessCode)}>{this.props.content.title}</a>
                    </Ripples>
                </h1>
                <div className="menu">
                    <TooltipLink content={this.props.content.contact.tooltip} position="bottom">
                        <a aria-label={this.props.content.download} href={appendParamToLink('/contact', this.props.accessCode)}>
                            <Ripples onClick={(e) => this.props.onOpen(e, this.props.content.contact.text)}>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </Ripples>
                        </a>
                    </TooltipLink>
                    <TooltipLink content={this.props.content.download} position="bottom">
                        <a href={`pdf/${this.props.content.pdfFile}`} aria-label={this.props.content.download} target="_blank" rel="noreferrer">
                            <Ripples>
                                <FontAwesomeIcon icon={faCloudArrowDown} />
                            </Ripples>
                        </a>
                    </TooltipLink>
                </div>
            </div>
        </header>);
    }
}