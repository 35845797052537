import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCircleNotch, faCheck, faRotateLeft } from '@fortawesome/free-solid-svg-icons';

import './CustomModal.css';

export class CustomModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submit: false,
            status: null
        }

        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.onFailure = this.onFailure.bind(this);
        this.getButton = this.getButton.bind(this);
    }

    handleClose() {
        this.setState({
            submit: false,
            status: null
        });

        this.props.onClose();
    }

    handleSubmit() {
        this.setState({
            submit: true
        });
    }

    onSuccess() {
        this.setState({
            status: true
        });
    }

    onFailure() {
        this.setState({
            submit: false,
            status: false
        });
    }

    getButton() {
        if (!!this.props.children.props.content.buttons) {
            if (this.state.submit === false && this.state.status === null) {
                return <Button variant="primary" onClick={this.handleSubmit} aria-label={this.props.children.props.content.do} disabled={false}>
                    <><FontAwesomeIcon icon={this.props.children.props.mainActionIcon} />{this.props.children.props.content.buttons.do}</>
                </Button>;
            }
            else if (this.state.submit === true && this.state.status === null) {
                return <Button variant="primary" onClick={this.handleSubmit} aria-label={this.props.children.props.content.doing} disabled={true}>
                    <><FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />{this.props.children.props.content.buttons.doing}</>
                </Button>;
            }
            else if (this.state.submit === true && this.state.status === true) {
                return <Button variant="primary" onClick={this.handleSubmit} aria-label={this.props.children.props.content.done} disabled={true}>
                    <><FontAwesomeIcon icon={faCheck} />{this.props.children.props.content.buttons.done}</>
                </Button>;
            }

            return <Button variant="primary" onClick={this.handleSubmit} aria-label={this.props.content.retry} disabled={false}>
                <><FontAwesomeIcon icon={faRotateLeft} />{this.props.content.retry}</>
            </Button>;
        }
    }
    render() {
        return (<Modal show={this.props.show} onHide={this.handleClose}>
            <Modal.Header closeButton closeVariant={localStorage.getItem('dark') === 'true' ? 'white' : null}>
                <Modal.Title>{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {React.cloneElement(this.props.children, {
                    ...this.props.children.props,
                    submit: this.state.submit,
                    onSuccess: this.onSuccess,
                    onFailure: this.onFailure
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose} aria-label={this.props.content.close}>
                    <FontAwesomeIcon icon={faXmark} />{this.props.content.close}
                </Button>
                {this.getButton()}
            </Modal.Footer>
        </Modal>);
    }
}