import React, { Component } from 'react';
import { BoxContent } from './BoxContent';
import { Column } from './Column';

export class LanguageColumn extends Component {
    render() {
        return (<BoxContent id={this.props.content.id} title={this.props.content.title}>
            {this.props.content.items.map((x, i) => {
                return (<Column index={this.props.content.items.length} key={i} onClick={(e) => this.props.onClick(x, e.currentTarget, i)}>
                    <img src={`images/${x.picture}`} className="img" alt={x.title} />
                    <h3 id={x.id}>{x.title}</h3>
                    <div className="more"><span className="level">{x.level}</span><span className="more-information">{x.more}</span></div>
                </Column>);
            })}
            <div className="clear"></div>
        </BoxContent>);
    }
}