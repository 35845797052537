import React, { Component } from 'react';
import emailjs from '@emailjs/browser';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Form } from './Form';

import { isValidEmail } from '../utils';

export class ContactForm extends Component {
    constructor(props) {
        super(props);

        this.validateContactForm = this.validateContactForm.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
    }

    validateContactForm(field, value, errors) {
        let fieldErrors = [];
        switch (field.type) {
            case 'email':
                if (!isValidEmail(value)) {
                    fieldErrors.push(errors[1].format(field.label));
                }
                break;
            default:
                break;
        }

        return fieldErrors;
    }

    sendEmail(hasErrors, formData) {
        if (hasErrors) {
            this.props.onFailure();
            return;
        };

        emailjs.send(process.env.REACT_APP_SERVICE_NAME, process.env.REACT_APP_FORM_NAME, formData, process.env.REACT_APP_PUBLIC_KEY)
            .then((response) => {
                this.props.onSuccess();
            }, (error) => {
                this.props.onFailure();
            });
    }

    render() {
        return (<Form content={this.props.content}
            submit={this.props.submit}
            validation={this.validateContactForm}
            action={this.sendEmail}
        />);
    }
}

ContactForm.defaultProps = {
    mainActionIcon: faPaperPlane
};