import React, { Component } from 'react';
import { BoxContent } from './BoxContent';
import { Badges } from './Badges';

export class SkillsRow extends Component {
    render() {
        return (<BoxContent id={this.props.content.id} title={this.props.content.title}>
            <Badges index={1} iteration={0} item={this.props.content.items[0]} onClick={this.props.onClick} />
            <div className="clear"></div>
            <hr />
            {this.props.content.items.slice(1).map((x, i) => {
                return (<React.Fragment key={i}>
                    <Badges index={2} iteration={i + 1} item={x} onClick={this.props.onClick} />
                    {i < this.props.content.items.length - 2 && <hr style={{ display: 'none' }} />}
                </React.Fragment>);
            })}
            <div className="clear"></div>
        </BoxContent>);
    }
}