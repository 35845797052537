import React, { Component } from 'react';
import { TooltipLink } from './TooltipLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faGlobe, faHome, faMapMarker, faEllipsisH, faPhone, faAt, faGear, faCircleHalfStroke, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faSkype, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { appendParamToLink } from '../utils';

import './LeftPanel.css';

export class LeftPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDark: localStorage.getItem('dark') === 'true',
            isHighContrast: localStorage.getItem('contrast') === 'true'
        };
    }

    componentDidMount() {
        if (this.state.isDark) {
            this.toggleTheme(true);
        }

        if (this.state.isHighContrast) {
            this.toggleContrast(true);
        }
    }

    getAge(dateOfBirth) {
        var today = new Date();
        var birthDate = new Date(dateOfBirth);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    toggleContrast(force) {
        if (!force || (force && !document.body.classList.contains('contrast'))) {
            document.body.classList.toggle("contrast");
        }

        this.setState({
            isHighContrast: document.body.classList.contains('contrast')
        }, () => {
            this.props.onToggleContrast(this.state.isHighContrast);
            localStorage.setItem('contrast', this.state.isHighContrast.toString());
        });
    }

    toggleTheme(force) {
        if (!force || (force && !document.body.classList.contains('dark'))) {
            document.body.classList.toggle("dark");
        }

        this.setState({
            isDark: document.body.classList.contains('dark')
        }, () => {
            this.props.onToggleTheme(this.state.isDark);
            var meta = document.getElementsByName('theme-color')[0];
            meta.content = this.state.isDark ? "#000000" : "#0d3458";
            localStorage.setItem('dark', this.state.isDark.toString());
        });
    }

    render() {
        return (<div id="left-panel" className="column">
            <div className="box-content first-box-content"><div className="panel-content"><span>#SoftwareDev #FullStackDev</span></div></div>
            <div className="box-content">
                <h2><FontAwesomeIcon icon={faInfoCircle} />{this.props.content.about.title}</h2>
                <div className="panel-content">
                    <FontAwesomeIcon icon={faGlobe} />{this.props.content.about.nationality}<br />
                    <FontAwesomeIcon icon={faHome} />{this.props.content.about.from.text}{this.props.content.about.from.text.endsWith("'") ? '' : ' '}<a className="maps" href={this.props.content.about.from.url} rel="noreferrer" target="_blank">{this.props.content.about.from.value}</a><br />
                    <FontAwesomeIcon icon={faMapMarker} />{this.props.content.about.base.text} <a className="maps" href={this.props.content.about.base.url} rel="noreferrer" target="_blank">{this.props.content.about.base.value}</a><br />
                    <FontAwesomeIcon icon={faEllipsisH} />{this.props.content.about.more.dateOfBirth ? `${this.getAge(this.props.content.about.more.dateOfBirth)} ${this.props.content.about.more.age}, ` : null}{this.props.content.about.more.status}, {this.props.content.about.more.license}
                    <hr />
                    <p>{this.props.content.about.description}</p>
                </div>
            </div>
            <div className="box-content">
                <h2><FontAwesomeIcon icon={faWhatsapp} />{this.props.content.contact.title}</h2>
                <div className="panel-content">
                    <FontAwesomeIcon icon={faPhone} />
                    <TooltipLink content={this.props.content.contact.phone.value} position="top">
                        {this.props.content.contact.phone.text ?
                        <a className="contact-info" href={`tel:+${this.props.content.contact.phone.text.countryCode} ${this.props.content.contact.phone.text.number}`}>
                            <span className="country-indicator">+{this.props.content.contact.phone.text.countryCode}</span> {this.props.content.contact.phone.text.number}
                        </a>
                        : <a className="contact-info blurred" href='/accessCode' onClick={(e) => this.props.onOpen(e, this.props.content.contact.accessCode)}>
                            <span className="country-indicator">+X</span> (XXX) XXX-XXXX
                        </a>}
                    </TooltipLink>
                    <br />

                    <FontAwesomeIcon icon={faAt} />
                    <TooltipLink content={this.props.content.contact.email.value} position="top">
                        {this.props.content.contact.email.text ?
                        <a className="contact-info" href={`mailto:${this.props.content.contact.email.text}`}>{this.props.content.contact.email.text}</a>
                        : <a className="contact-info blurred" href='/accessCode' onClick={(e) => this.props.onOpen(e, this.props.content.contact.accessCode)}>xxxxxxxXX@xxxxxxx.xxx</a>}
                    </TooltipLink>
                    <br />

                    <FontAwesomeIcon icon={faSkype} />
                    <TooltipLink content={this.props.content.contact.skype.value} position="top">
                        <a className="contact-info" href={`skype:${this.props.content.contact.skype.text}?add`}>{this.props.content.contact.skype.text}</a>
                    </TooltipLink>
                    <br />

                    <FontAwesomeIcon icon={faLinkedin} />
                    <TooltipLink content={this.props.content.contact.linkedIn.value} position="top">
                        <a className="contact-info" href={`https://www.linkedin.com/in/${this.props.content.contact.linkedIn.text}/`} rel="noreferrer" target="_blank">{this.props.content.contact.linkedIn.text}</a>
                    </TooltipLink>
                </div>
            </div>
            <div className="box-content">
                <h2><FontAwesomeIcon icon={faGear} />{this.props.content.localization.title}</h2>
                <div className="panel-content">
                    <div className="settings localization">
                        {this.props.content.localization.languages.map((x, i) => {
                            return <React.Fragment key={i}>
                                <TooltipLink content={x.text} position="top">
                                    <a href={appendParamToLink(`?locale=${x.code}`, this.props.accessCode)}
                                        style={this.props.language === x.code ? { display: 'none' } : { display: 'inline' }}
                                        onClick={(e) => this.props.changeLanguage(e, x.code)}>{x.name}</a>
                                </TooltipLink>
                                {i !== this.props.content.localization.languages.length - 1 && this.props.language !== x.code ? <> &middot; </> : null}
                                <span className="active"
                                    style={this.props.language === x.code ? { display: 'inline' } : { display: 'none' }}>{x.name}</span>
                                {i !== this.props.content.localization.languages.length - 1 && this.props.language === x.code ? <> &middot; </> : null}
                            </React.Fragment>;
                        })}
                    </div>
                    
                    <div className="settings theme">
                        <TooltipLink content={this.state.isDark ? this.props.content.theme.light : this.props.content.theme.dark} position="top">
                            <button aria-label={this.state.isDark ? this.props.content.theme.light : this.props.content.theme.dark} 
                                onClick={() => this.toggleTheme(false)}>
                                {this.state.isDark ?
                                    <FontAwesomeIcon icon={faSun} size="2xl" /> :
                                    <FontAwesomeIcon icon={faMoon} size="2xl" />
                                }
                            </button>
                        </TooltipLink>
                    </div>

                    <div className="settings contrast">
                        <TooltipLink content={this.state.isHighContrast ? this.props.content.contrast.disable : this.props.content.contrast.enable} position="top">
                            <button aria-label={this.state.isHighContrast ? this.props.content.contrast.disable : this.props.content.contrast.enable}
                                className={this.state.isHighContrast ? "active" : null}
                                onClick={() => this.toggleContrast(false)}>
                                    <FontAwesomeIcon icon={faCircleHalfStroke} size="2xl" />
                            </button>
                        </TooltipLink>
                    </div>
                </div>
            </div>
        </div>);
    }
}