import React, { Component } from 'react';

import './Menu.css';

export class Menu extends Component {
    render() {
        return (<ul id="menu">
            {Object.keys(this.props.content).map((x, i) => {
                return (<li key={i}><a className="js-scrollTo" href={`#${this.props.content[x].id}`}>{this.props.content[x].title}</a>
                    <ul>
                        {this.props.content[x].items.map((y, j) => {
                            return (<li key={j}><a className="js-scrollTo" href={`#${y.id}`}>{y.title}</a></li>);
                        })}
                    </ul>
                </li>);
            })}
        </ul>);
    }
}