import React, { Component } from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import AppleMapsesque from './Maps.apple-maps-esque.json';
import SimpleNightVision from './Maps.simple-night-vision-stranger-thing.json';

import './Map.css';

export class Map extends Component {
    render() {
        return (<LoadScript
            key={`${this.props.title} - ${this.props.language}`}
            googleMapsApiKey={process.env.REACT_APP_GOOGLEMAPS_API_KEY}
            libraries={['places']}
            language={this.props.language}
            region={this.props.language}
        >
            <GoogleMap
                mapContainerClassName='map-container'
                zoom={this.props.maps.zoom}
                center={{ lat: this.props.maps.latitude, lng: this.props.maps.longitude }}
                options={{ mapTypeControlOptions: { mapTypeIds: [] }, styles: this.props.isDark ? SimpleNightVision : AppleMapsesque }}
            >
                <Marker title={this.props.title} position={{ lat: this.props.maps.latitude, lng: this.props.maps.longitude }} />
            </GoogleMap>
        </LoadScript>);
    }
}
