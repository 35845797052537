import React, { Component } from 'react';
import { BoxContent } from './BoxContent';
import { Row } from './Row';

import { getDate } from '../utils';

export class BaggageRow extends Component {
    isCurrent(item) {
        return this.props.content.id === "professional-experience" && !item.date.to;
    }

    render() {
        return (<BoxContent id={this.props.content.id} title={this.props.content.title}>
            {this.props.content.items.map((x, i) => {
                return (
                    <React.Fragment key={i}>
                        <Row onClick={(e) => this.props.onClick(x, e.currentTarget, i)} current={this.isCurrent(x)}>
                            <div>
                                <span className="helper"></span><img src={`images/${x.company.logo}`} className="img" alt={x.company.name} />
                            </div>
                            <div>
                                <h3 id={x.id}>{x.title}</h3>
                                <span className="place">{x.company.text}{x.company.text.endsWith("'") ? '' : ' '}<a href={x.company.url} target="_blank" rel="noreferrer">{x.company.name}</a></span>
                                <span className="about-place">{x.company.about}</span>
                                <div className="more">
                                    <span className="date">{x.date.text.format(getDate(x.date.from, this.props.language), getDate(x.date.to, this.props.language))}</span>&nbsp;&middot;&nbsp;
                                    <span className="city"><a className="maps" href={x.place.url} rel="noreferrer" target="_blank">{x.place.value}</a></span>
                                    <span className="more-information">{x.more}</span>
                                </div>
                            </div>
                        </Row>
                        <hr />
                    </React.Fragment>);
            })}
        </BoxContent >);
    }
}