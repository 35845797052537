import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Layout from './components/Layout';

import './App.css';
import './dark.css';
import './contrast.css';

export default class App extends Component {
    render() {
        return (<div id="App">
            <Router>
                <Layout />
            </Router>
        </div>);
    }
}
