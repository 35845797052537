import React, { Component } from 'react';
import { LighthouseGauge } from './LighthouseGauge';

import './LighthouseSummary.css';

export class LighthouseSummary extends Component {
    render() {
        return (<div className="lighthouse-summary">
            <p className="title">{this.props.content.text}</p>
            <div className="lh-scores-header">
                {this.props.content.gauges.map((x, i) => {
                    return <LighthouseGauge key={i} title={x.title} score={x.score} />;
                })}
                <a className="lh-gauge__wrapper lh-gauge--pwa__wrapper lh-badged--all" title="Installable: 1/1, PWA Optimized: 6/6" href="#pwa" onClick={(e) => e.preventDefault()}>
                    <svg className="lh-gauge lh-gauge--pwa" viewBox="0 0 60 60">
                        <defs>
                            <linearGradient id="lh-gauge--pwa__check-circle__gradient-0" x1="50%" y1="0%" x2="50%" y2="100%">
                                <stop stopColor="#00C852" offset="0%"/>
                                <stop stopColor="#009688" offset="100%"/>
                            </linearGradient>
                            <linearGradient id="lh-gauge--pwa__installable__shadow-gradient-0" x1="76.056%" x2="24.111%" y1="82.995%" y2="24.735%">
                                <stop stopColor="#A5D6A7" offset="0%"/>
                                <stop stopColor="#80CBC4" offset="100%"/>
                            </linearGradient>
                            <g id="lh-gauge--pwa__installable-badge-0">
                                <circle fill="#FFFFFF" cx="10" cy="10" r="10"/>
                                <path fill="#009688" d="M10 4.167A5.835 5.835 0 0 0 4.167 10 5.835 5.835 0 0 0 10 15.833 5.835 5.835 0 0 0 15.833 10 5.835 5.835 0 0 0 10 4.167zm2.917 6.416h-2.334v2.334H9.417v-2.334H7.083V9.417h2.334V7.083h1.166v2.334h2.334v1.166z"/>
                            </g>
                        </defs>
                        <g stroke="none" fillRule="nonzero">
                            <circle className="lh-gauge--pwa__disc" cx="30" cy="30" r="30"/>
                            <g className="lh-gauge--pwa__logo">
                                <path className="lh-gauge--pwa__logo--secondary-color" d="M35.66 19.39l.7-1.75h2L37.4 15 38.6 12l3.4 9h-2.51l-.58-1.61z"/>
                                <path className="lh-gauge--pwa__logo--primary-color" d="M33.52 21l3.65-9h-2.42l-2.5 5.82L30.5 12h-1.86l-1.9 5.82-1.35-2.65-1.21 3.72L25.4 21h2.38l1.72-5.2 1.64 5.2z"/>
                                <path className="lh-gauge--pwa__logo--secondary-color" fillRule="nonzero" d="M20.3 17.91h1.48c.45 0 .85-.05 1.2-.15l.39-1.18 1.07-3.3a2.64 2.64 0 0 0-.28-.37c-.55-.6-1.36-.91-2.42-.91H18v9h2.3V17.9zm1.96-3.84c.22.22.33.5.33.87 0 .36-.1.65-.29.87-.2.23-.59.35-1.15.35h-.86v-2.41h.87c.52 0 .89.1 1.1.32z"/>
                            </g>
                            <rect className="lh-gauge--pwa__component lh-gauge--pwa__na-line" fill="#FFFFFF" x="20" y="32" width="20" height="4" rx="2"/>
                            <g className="lh-gauge--pwa__component lh-gauge--pwa__installable-badge" transform="translate(20, 29)">
                                <path fill="url(#lh-gauge--pwa__installable__shadow-gradient-0)" d="M33.629 19.487c-4.272 5.453-10.391 9.39-17.415 10.869L3 17.142 17.142 3 33.63 19.487z"/>
                                <use href="#lh-gauge--pwa__installable-badge-0"/>
                            </g>
                            <g className="lh-gauge--pwa__component lh-gauge--pwa__check-circle" transform="translate(18, 28)">
                                <circle fill="#FFFFFF" cx="12" cy="12" r="12"/>
                                <path fill="url(#lh-gauge--pwa__check-circle__gradient-0)" d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
                            </g>
                        </g>
                    </svg>
                    <div className="lh-gauge__label">PWA</div>
                </a>
            </div>            
            <p className="source">{this.props.content.source.split('#')[0]} <a href={this.props.content.url} target='_blank' rel='noreferrer'>{this.props.content.source.split('#')[1]}</a></p>
        </div>);
    }
}