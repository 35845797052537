import React, { Component } from 'react';
import { TooltipLink } from './TooltipLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { getDate } from '../utils';

import './InfoBox.css';
import { Map } from './Map';

export class InfoBox extends Component {
    render() {
        return (<div id="panel-information" className="box-content closable">
            <TooltipLink content={this.props.global.close} position="top">
                <div className="close-button">
                    <FontAwesomeIcon icon={faTimes} />
                </div>
            </TooltipLink>

            {this.props.values.content &&
                <>
                    <div className="header">
                        <div dangerouslySetInnerHTML={{ __html: this.props.values.icon.outerHTML }}></div>
                        <span className="title">{this.props.values.content.title}</span>
                    </div>
                    {this.props.values.content.company &&
                        <>
                            <Map loadingElement={<div style={{ height: `250px` }}><FontAwesomeIcon icon={faCircleNotch} className="fa-spin" /></div>}
                                containerElement={<div style={{ height: `250px` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                maps={this.props.values.content.company.maps}
                                language={this.props.language}
                                title={this.props.values.content.company.name}
                                isDark={this.props.isDark} />
                            <div className="information-line place">
                                <label>{this.props.content.place}</label>:&nbsp;
                                {this.props.values.content.company.text}
                                {this.props.values.content.company.text.endsWith("'") ? '' : ' '}
                                <a href={this.props.values.content.company.url} target="_blank" rel="noreferrer">
                                    {this.props.values.content.company.name}
                                </a> (<a className="maps" href={this.props.values.content.place.url} rel="noreferrer" target="_blank">
                                    {this.props.values.content.place.value}
                                </a>)
                            </div>
                        </>
                    }
                    {this.props.values.content.date &&
                        <div className="information-line date">
                            <label>{this.props.content.date}</label>:&nbsp;{this.props.values.content.date.text.format(getDate(this.props.values.content.date.from, this.props.language), getDate(this.props.values.content.date.to, this.props.language))}
                        </div>
                    }
                    {this.props.values.content.company &&
                        <div className="information-line about-place">
                            <label>{this.props.content.about.format(this.props.values.content.company.name)}</label>:&nbsp;{this.props.values.content.company.about}
                        </div>
                    }
                    {this.props.values.content.level &&
                        <div className="information-line level">
                            <label>{this.props.content.level}</label>:&nbsp;{this.props.values.content.level}
                        </div>
                    }
                    {this.props.values.content.more &&
                        <div className="information-line more">
                            <label>{this.props.content.more}</label>:&nbsp;{this.props.values.content.more}
                        </div>
                    }
                </>
            }
        </div>);
    }
}