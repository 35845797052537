import React, { Component } from 'react';
import { TooltipLink } from './TooltipLink';
import { appendParamToLink } from '../utils';

import './FooterMobile.css';

export class FooterMobile extends Component {
    render() {
        return (<div id="footer-mobile" className="box-content">
            <TooltipLink content={this.props.content.about.tooltip} position="top">
                <a href={appendParamToLink('/about', this.props.accessCode)} onClick={(e) => this.props.onOpen(e, this.props.content.about.text)}>{this.props.content.about.text}</a>
            </TooltipLink>
            &nbsp;&middot;&nbsp;
            <TooltipLink content={this.props.content.contact.tooltip} position="top">
                <a href={appendParamToLink('/contact', this.props.accessCode)} onClick={(e) => this.props.onOpen(e, this.props.content.contact.text)}>{this.props.content.contact.text}</a>
            </TooltipLink>
            &nbsp;&middot;&nbsp;Florent Schmitt &copy; {new Date().getFullYear()}
        </div>);
    }
}