import React, { Component } from 'react';
import { TooltipLink } from './TooltipLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import './Badge.css';

export class Badge extends Component {
    render() {
        return (
            <div className={`badge-container ${this.props.technology.display}`} style={this.props.technology.width && { width: this.props.technology.width }}>
                <div className="badge">
                    {this.props.technology.name}
                    <TooltipLink content={this.props.technology.level.name} position="top">
                        <div className="score">
                            {Array.from(Array(this.props.technology.level.id), (x, i) => {
                                return (<FontAwesomeIcon key={i} icon={faCircle} className="level" />);
                            })}
                        </div>
                    </TooltipLink>
                </div>
            </div>
        );
    }
}