import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faTerminal, faCubesStacked } from '@fortawesome/free-solid-svg-icons';
import { Badge } from './Badge';
import { Column } from './Column';

export class Badges extends Component {
    constructor(props) {
        super(props);

        this.iconMapping = {
            'programming': faCode,
            'system': faTerminal,
            'software': faCubesStacked,
        };
    }

    render() {
        return (
            <Column index={this.props.index} onClick={(e) => this.props.onClick(this.props.item, e.currentTarget, this.props.iteration)}>
                <div className={`img ${this.props.item.id}`}>
                    <FontAwesomeIcon icon={this.iconMapping[this.props.item.id]} />
                </div>
                <h3 id={this.props.item.id}>{this.props.item.title}</h3>
                <div className={`badges highlight ${this.props.item.id}`}>
                    {this.props.item.technologies.filter(x => x.display === "highlight").sort((a, b) => b.level.id - a.level.id).map((x, i) => {
                        return (<Badge key={i} technology={x} />);
                    })}
                </div>
                <div className={`badges ${this.props.item.id}`}>
                    {this.props.item.technologies.filter(x => x.display === "normal").map((x, i) => {
                        return (<Badge key={i} technology={x} />);
                    })}
                </div>
                <div className="more"><span className="more-information">{this.props.item.more}</span></div>
            </Column>
        );
    }
}