import React, { Component } from 'react';
import Ripples from 'react-ripples';

import './Row.css';

export class Row extends Component {
    render() {
        return (<Ripples>
            <div className={`row ${this.props.current ? 'current' : ''}`} onClick={(x, e) => this.props.onClick(x, e)}>
                {this.props.children}
            </div>
        </Ripples>);
    }
}