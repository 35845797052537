import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { faFutbol } from '@fortawesome/free-solid-svg-icons';

export class BoxContent extends Component {
	constructor(props) {
		super(props);

		this.iconMapping = {
			'professional-experience': faSuitcase,
			'degrees-and-education': faGraduationCap,
			'linguistic-skills': faLanguage,
			'computer-skills': faDesktop,
			'hobbies': faFutbol,
		};
	}

	render() {
		return (<div className="box-content">
			<h2 id={this.props.id} ><FontAwesomeIcon icon={this.iconMapping[this.props.id]} />{this.props.title}</h2>
			<div className="panel-content">
				{this.props.children}
			</div>
		</div>);
	}
}