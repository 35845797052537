function writeText(text) {
    ctx.font = "15px Monospace";
    ctx.textAlign = "center";
    ctx.fillText(text, canvas.width / 2, canvas.height / 2);
}

function writeSubText(text) {
    ctx.font = "10px Monospace";
    ctx.textAlign = "center";
    ctx.fillText(text, canvas.width / 2, canvas.height / 2 + 30);
}

function clearCanvas(canvas, canvasContext) {
	canvasContext.clearRect(0, 0, canvas.width, canvas.height);
}

var controls = {
    rightPressed: false,
    leftPressed: false
}

var keys = {
	leftKey: 65,
	rightKey: 68
};

document.addEventListener("keydown", keyDownHandler);
document.addEventListener("keyup", keyUpHandler);

function keyDownHandler(e) {
	if (e.keyCode === 39 || e.keyCode === keys.rightKey) {
        controls.rightPressed = true;
	}
	else if (e.keyCode === 37 || e.keyCode === keys.leftKey) {
        controls.leftPressed = true;
    }
}
function keyUpHandler(e) {
	if (e.keyCode === 39 || e.keyCode === keys.rightKey) {
        controls.rightPressed = false;
    }
	else if (e.keyCode === 37 || e.keyCode === keys.leftKey) {
        controls.leftPressed = false;
    }
}

var BALL_COLOR = "#fff";
var PADDLE_COLOR = "#fff";
var BRICK_COLOR = "#fff";

function initializeBall() {
	return {
		position: { x: 0, y: 0 },
		velocity: { x: 0, y: 0 },
		radius: 5,
		color: BALL_COLOR,
		colliderDifference: 5,
		physics: [],
		draw: function (canvasContext) {
			canvasContext.beginPath();
			canvasContext.arc(this.position.x, this.position.y, this.radius, 0, Math.PI * 2);
			canvasContext.fillStyle = this.color;
			canvasContext.fill();
			canvasContext.closePath();
		},
		applyVelocity: function () {
			this.position.x += this.velocity.x / (2 - (level - 1) / 10);
			this.position.y += this.velocity.y / (2 - (level - 1) / 10);
		},
		bounce: function (canvas) {
			var i = 0
			var hitX = false;
			for (i = 0; i < this.physics.length; i++) {
				hitX = hitX || this.collisionX(this.physics[i]);
				if (hitX) {
					if (this.physics[i].canBeDestroyed) {
						this.physics[i].destroy();
						this.physics.splice(i, 1);
					}
					break;
				}
			}
			var oocX = this.isOutOfCanvasX(canvas);
			if (oocX || hitX) 
				this.velocity.x = -this.velocity.x;
	
			var hitY = false;
			var j = 0
			for (j = 0; j < this.physics.length; j++) {
				hitY = hitY || this.collisionY(this.physics[j]);
				if (hitY) {
					if (this.physics[j].canBeDestroyed) {
						this.physics[j].destroy();
						this.physics.splice(j, 1);
					}
					break;
				}
			}
			var oocY = this.isOutOfCanvasY(canvas);
			if (oocY || hitY)
				this.velocity.y = -this.velocity.y;
		},
		isOutOfCanvasX: function (canvas) {
			return this.position.x + this.velocity.x > canvas.width - this.radius + this.colliderDifference || this.position.x + this.velocity.x < this.radius - this.colliderDifference;
		},
		isOutOfCanvasY: function (canvas) {
			return this.position.y + this.velocity.y < this.radius - this.colliderDifference || this.position.y + this.velocity.y > canvas.height - this.radius + this.colliderDifference;
		},
		collisionX: function (something) {
			return this.position.y + this.radius > something.position.y && this.position.y - this.radius < something.position.y + something.height &&
				((Math.floor(this.position.x) - this.radius + this.colliderDifference === Math.floor(something.position.x) + something.width && this.velocity.x < 0) ||
					(Math.floor(this.position.x) + this.radius - this.colliderDifference === Math.floor(something.position.x) && this.velocity.x >= 0));
		},
		collisionY: function (something) {
			return this.position.x + this.radius > something.position.x && this.position.x - this.radius < something.position.x + something.width &&
				((Math.floor(this.position.y) - this.radius + this.colliderDifference === Math.floor(something.position.y) + something.height && this.velocity.y < 0) ||
					(Math.floor(this.position.y) + this.radius - this.colliderDifference === Math.floor(something.position.y) && this.velocity.y >= 0));
		},
		start: function (position, velocity) {
			this.position = position;
			this.velocity = velocity;
		},
		update: function (canvas, canvasContext) {
			this.draw(canvasContext);
			this.bounce(canvas);
			this.applyVelocity();
		}
	};
}

function initializePaddle() {
	return {
		height: 5,
		width: 60 - 60 * ((level - 1) / 10),
		position: { x: 0, y: 0 },
		color: PADDLE_COLOR,
		canBeDestroyed: false,
		draw: function (canvasContext) {
			canvasContext.beginPath();
			canvasContext.rect(this.position.x, this.position.y, this.width, this.height);
			canvasContext.fillStyle = this.color;
			canvasContext.fill();
			canvasContext.closePath();
		},
		control: function (canvas) {
			if (controls.rightPressed && !this.isOutOfCanvasLeft(canvas)) {
				this.position.x += 1;
			}
			else if (controls.leftPressed && !this.isOutOfCanvasRight(canvas)) {
				this.position.x -= 1;
			}
		},
		isOutOfCanvasLeft: function (canvas) {
			return this.position.x > canvas.width - this.width;
		},
		isOutOfCanvasRight: function (canvas) {
			return this.position.x < 0;
		},
		start: function (position) {
			this.position = position;
		},
		update: function (canvas, canvasContext) {
			this.control(canvas);
			this.draw(canvasContext);
		}
	};
}

function initializeBricks() {
	var bricks = [];

	for (var i = 0; i < 30; i++) {
		bricks.push({
			height: 6,
			width: 25,
			position: { x: 0, y: 0 },
			color: BRICK_COLOR,
			status: true,
			canBeDestroyed: true,
			destroy: function () {
				this.status = false;
			},
			draw: function (canvasContext) {
				if (this.status) {
					canvasContext.beginPath();
					canvasContext.rect(this.position.x, this.position.y, this.width, this.height);
					canvasContext.fillStyle = this.color;
					canvasContext.fill();
					canvasContext.closePath();
				}
			},
			start: function (position) {
				this.position = position;
			},
			update: function (canvasContext) {
				this.draw(canvasContext);
			}
		});
	}

	return bricks;
}

function initializeGame() {
	return {
		stop: false,
		alertShown: false,
		isGameOver: function (ball, canvas) {
			return ball.position.y + ball.velocity.y > canvas.height - ball.radius + ball.colliderDifference;
		},
		isGameWon: function (bricks) {
			var status = false;
			for (var i = 0; i < bricks.length; i++) {
				status = status || bricks[i].status;
				if (status) return false;
			}
			return !status;
		},
		gameOver: function () {
			if (!this.alertShown) {
				level = 1;
				writeText("GAME OVER");
				writeSubText("click to restart");
			}
			this.alertShown = true;
		},
		gameWon: function () {
			if (!this.alertShown) {
				if (level === 7) {
					writeText("YOU WON");
					writeSubText("click to restart");
					level = 1;
				}
				else {
					writeText(`Level ${level} completed!`);
					writeSubText("click to start next level...");
					++level;
				}
			}
			this.alertShown = true;
		},
		update: function (canvas, ball, bricks) {
			if (this.isGameOver(ball, canvas)) {
				this.gameOver();
				this.stop = true;
			}
			else if (this.isGameWon(bricks)) {
				this.gameWon();
				this.stop = true;
			}
		}
	};
}

var level = 1;
var ball = initializeBall();
var paddle = initializePaddle();
var bricks = initializeBricks();
var game = initializeGame();

var canvas;
var ctx;

export function start(init) {
	ball.physics.push(paddle);
    for (var i = 0; i < bricks.length; i++) {
        bricks[i].color = BRICK_COLOR;
        bricks[i].start({ x: 2 + (5 + bricks[i].width) * (i - 10 * Math.floor(i / 10)), y: bricks[i].height + 10 * (1 + Math.floor(i / 10)) });
		ball.physics.push(bricks[i]);
	}

	canvas = document.getElementById("bricksCanvas");
	
	if (init) {
		setInterval(update, 5);
	}

	ball.start({ x: canvas.width / 2, y: canvas.height - 30 }, init ? { x: 0, y: 0 } : { x: [-1, 1][Math.floor(Math.random() * 2)], y: -1 });
	paddle.start({ x: (canvas.width - paddle.width) / 2, y: canvas.height - paddle.height - 10 });
}

function update() {
	if (!game.stop) {
		ctx = canvas.getContext("2d");

		clearCanvas(canvas, ctx);
		game.update(canvas, ball, bricks);
		paddle.update(canvas, ctx);
		ball.update(canvas, ctx, paddle);
		for (var i = 0; i < bricks.length; i++) {
			bricks[i].update(ctx);
		}
	}
}


export function loadGame(language) {
	if (language === 'fr') {
		keys = {
			leftKey: 81,
			rightKey: 68
		}
	}

	if (level === 1 && ball.velocity.x === 0) {
		ball.start({ x: canvas.width / 2, y: canvas.height - 30 }, { x: [-1, 1][Math.floor(Math.random() * 2)], y: -1 });
	}
	
	if (game.stop) {
		ball = initializeBall();
		paddle = initializePaddle();
		bricks = initializeBricks();
		game = initializeGame();
		start(false);
	}
}