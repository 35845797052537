import React, { Component } from 'react';
import { faUnlockKeyhole } from '@fortawesome/free-solid-svg-icons';
import { Form } from './Form';
import { checkIntegrityAndDecode } from '../utils';

export class AccessCodeForm extends Component {
    constructor(props) {
        super(props);

        this.validateErrorCode = this.validateErrorCode.bind(this);
        this.decodeInformation = this.decodeInformation.bind(this);
    }
    
    validateErrorCode(field, value, errors) {
        let fieldErrors = [];
        let confidentialInfo = checkIntegrityAndDecode(value);
        if (confidentialInfo === null) {
            fieldErrors.push(errors[1].format(field.label));
        }
        else if (confidentialInfo.version.toString() !== process.env.REACT_APP_CONFIDENTIAL_INFORMATION_VERSION) {
            fieldErrors.push(errors[2].format(field.label));
            confidentialInfo = null;
        }

        this.props.setConfidentialInfo(confidentialInfo, value);
        return fieldErrors;
    }

    decodeInformation(hasErrors, formData) {
        if (hasErrors) {
            this.props.onFailure();
            return;
        };
        
        this.props.onSuccess();
    }

    render() {
        return (<Form content={this.props.content}
            submit={this.props.submit}
            validation={this.validateErrorCode}
            action={this.decodeInformation}
        />);
    }
}

AccessCodeForm.defaultProps = {
    mainActionIcon: faUnlockKeyhole
};