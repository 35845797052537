import React, { Component } from 'react';
import { Header } from './components/Header';

import { start, loadGame } from './breakout-game/game';

import './NotFound.css';

export class NotFound extends Component {
    constructor(props) {
        super(props);

        this.gameStarted = false;
    }

    componentDidMount() {
        start(true);
    }

    onClick(e, language) {
        if (!this.gameStarted) {
            e.target.classList.remove("inactive");
            e.target.previousSibling.innerHTML = '<p>Level 1<p><p>Click to start<p>';
            this.gameStarted = true;
        }
        else {
            e.target.classList.add("active");
            e.target.previousSibling.style.display = 'none';
            if (e.target.style.display !== 'none') {
                setTimeout(function() {
                    loadGame(language);
                }, 500);
            }
        }
    }

    render() {
        return (<div id="container">
            <Header className="transparent" content={this.props.header} onOpen={(e, content) => this.props.onOpen(e, content)} accessCode={this.props.accessCode} />
            <div className="error-404">
                <h2>404</h2>
                <div>
                    <p>{this.props.content.text}<span className="no-mobile"><br/>{this.props.content.more}</span></p>
                    <p className="no-mobile"><strong>{this.props.content.click}</strong></p>
                </div>

                <canvas id="bricksCanvas" className="no-mobile inactive" onClick={(e) => this.onClick(e, this.props.language)}></canvas>
            </div>
        </div>);
    }
}